"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

(function () {
  "use strict";

  var TopBarController = function () {
    /** @ngInject */
    function TopBarController(AuthService, $timeout, $window, $rootScope, CustomersService, ModalService, CustomListsService) {
      _classCallCheck(this, TopBarController);

      this.AuthService = AuthService;
      this.$rootScope = $rootScope;
      this.$timeout = $timeout;
      this.$window = $window;
      this.CustomersService = CustomersService;
      this.ModalService = ModalService;
      this.CustomListsService = CustomListsService;
    }

    _createClass(TopBarController, [{
      key: "$onInit",
      value: function $onInit() {
        var _this = this;

        if (this.$rootScope.$super) {
          //console.log('user is $super', this.$rootScope.$user)

          this.CustomersService.getSelectArray().then(function (array) {
            array = array.filter(function (element) {
              return element.active == true && element.typeCustomer === "customer" || element.name === "LVR";
            });

            _this.$timeout(function () {
              _this.list = array;
            }, 0);
          });
        }
        var previousVersion = localStorage.getItem("$version");
        if (!previousVersion || previousVersion !== this.$rootScope.$version) {
          localStorage.setItem("$version", this.$rootScope.$version);
          this.openReleaseNotes();
        }

        var changePassword = localStorage.getItem('$ChangePassword');
        if (changePassword === "true") {
          localStorage.setItem('$ChangePassword', "false");
          this.changePassword();
        }
      }
    }, {
      key: "emit",
      value: function emit() {
        if (this.$rootScope.$super) {
          localStorage.removeItem("last-depa-form");
          localStorage.setItem("$selectedCustomerId", this.$rootScope.$selectedCustomerId);
          location.reload();
        }
      }
    }, {
      key: "openReleaseNotes",
      value: function openReleaseNotes() {
        // <script src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.3/iframeResizer.contentWindow.min.js" integrity="sha512-yFEfhHcWqIxro+ve/4zqTeq1XbHl4vbtJqNoXpn7PbEH5D03jK3Cab2rUac60wnEb97iMwjDvCRgbrpNLeEiDg==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
        this.ModalService.openComponent("custom-iframe", {
          size: "lg",
          source: "releases/2.0.185.html", // WARNING: add iframe-resizer script and style="text-align: justify;"
          title: "v2.0.185",
          heightCalculationMethod: "lowestElement",
          closeEvent: "init",
          pdfLink: "releases/2.0.185.pdf"
        }).then(function () {
          console.log("close modal");
        }).catch(function (err) {
          console.error("err", err);
        });
      }
    }, {
      key: "openTutorial",
      value: function openTutorial() {
        this.$rootScope.openTutorial();
      }
    }, {
      key: "logout",
      value: function logout() {
        this.AuthService.logout();
      }
    }, {
      key: "myProfile",
      value: function myProfile() {
        this.ModalService.openComponent("user-info", { id: this.$rootScope.$user.id, size: "lg" }).then(function (id) {}).catch(function () {});
      }
    }, {
      key: "changePassword",
      value: function changePassword() {
        console.log("change password");

        this.ModalService.openComponent("changePassword", { size: "lg" }).then(function () {
          console.log("changePassword ok");
        }).catch(function (err) {
          console.log("err", err);
        });
      }
    }]);

    return TopBarController;
  }();

  angular.module("menu").component("appTopBar", {
    templateUrl: "app/menu/topBar/topBar.html",
    controller: TopBarController
  });
})();