"use strict";

(function () {
  "use strict";

  angular.module("common").directive("ngUploadImage", ngUploadImage);

  function ngUploadImage() {
    return {
      restrict: "EA",
      scope: {
        docs: "=",
        multiple: "="
      },
      templateUrl: "app/common/directive/ngUploadImage/ngUploadImage.html",

      controller: NgUploadImageCtrl,
      controllerAs: "vm",
      bindToController: true
    };
  }

  function NgUploadImageCtrl($scope, $element, $attrs, $timeout, SERVER_URL, Upload) {
    var vm = this;

    vm.progressStatus = {};

    if (!vm.docs) {
      vm.docs = [];
    }

    console.log("vm.toto", vm.toto);

    // if (!vm.toto) {
    //     vm.multiple = false
    // }

    $scope.$watch("vm.files", function () {
      if (vm.files) {
        console.log("new files", Array.isArray(vm.files), vm.files);

        if (Array.isArray(vm.files)) {
          vm.uploads(vm.files);
        } else {
          vm.uploads([vm.files]);
        }
      }
    });

    $scope.$watch("vm.file", function () {
      console.log("new file", vm.file);
      if (vm.file != null) {
        vm.files = [vm.file];
      }
    });

    vm.log = "";

    vm.upload = function (file) {
      if (file) {

        Notiflix.Loading.standard("Cliquez pour interrompre le chargement...");

        var upload = Upload.upload({
          url: SERVER_URL + "/loadFile",
          headers: {
            Authorization: localStorage.getItem("$LoopBack$accessTokenId")
          },

          data: {
            file: file
          }
        }).then(function (resp) {
          Notiflix.Loading.remove();

          console.log("resp", resp);
          $timeout(function () {
            if (resp.status == 200 && resp.data.success) {
              var newDoc = {
                url: resp.data.url,
                thumb: resp.data.thumb,
                name: resp.config.data.file.name,
                type: resp.config.data.file.type
              };

              if (vm.multiple) {
                if (!vm.docs || !Array.isArray(vm.docs)) {
                  vm.docs = [];
                }

                vm.docs.push(newDoc);
              } else {
                vm.docs = [newDoc];
              }
            } else {
              Notiflix.Notify.failure(resp.data.error);
            }
          });
        }, null, function (evt) {
          var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);

          $timeout(function () {
            vm.progressStatus[evt.config.data.file.name] = progressPercentage;
          }, 0);

          console.log("vm.progressStatus", vm.progressStatus);

          //console.log('files', files)
        });
      }
    };

    vm.uploads = function (files) {
      if (files && files.length) {
        for (var i = 0; i < files.length; i++) {
          var file = files[i];
          if (!file.$error) {
            vm.upload(file);
          }
        }
      }
    };
  }
})();