"use strict";

(function () {
  "use strict";

  Ctrl.$inject = ["vm", "moment"];
  angular.module("customers").controller("CustomersCtrl.simpleExport", Ctrl);

  function Ctrl(vm, moment) {
    vm.simpleExport = function () {
      console.log("in simpleExport");

      var convertDateWithTz = function convertDateWithTz(oneDate) {
        return new Date(moment.tz(oneDate, "Europe/Paris").format("YYYY-MM-DDTHH:mm:ss") + ".000Z");
      };

      var data = [];
      var rowsHeight = [{ hpt: 15 }]; // first row
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = vm.rawActiveCustomer[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var customer = _step.value;

          try {

            console.log(customer.statsPerYear["N0"] ? customer.statsPerYear["N0"].cost : "");

            var row = {
              "Courtier": customer.brokerSellsyName,
              "Compagnie assurance": customer.insurerSellsyName,
              "Client": customer.name,
              "Type de roulants": customer.driverTypeNames ? customer.driverTypeNames.join(", ") : "",
              "Type de flotte": customer.fleetTypeNames ? customer.fleetTypeNames.join(", ") : "",
              "Nombre de moteurs suivis": customer.nbVehiclesNb,
              "Date début de contrat": customer.startDate ? convertDateWithTz(customer.startDate) : "",
              "Fréquence N-1": customer.statsPerYear["N-1"] && customer.statsPerYear["N-1"].frequency ? customer.statsPerYear["N-1"].frequency : "",
              "Fréquence N": customer.statsPerYear["N0"] && customer.statsPerYear["N0"].frequency ? customer.statsPerYear["N0"].frequency : "",
              "Fréquence N+1": customer.statsPerYear["N+1"] && customer.statsPerYear["N+1"].frequency ? customer.statsPerYear["N+1"].frequency : "",
              "Fréquence N+2": customer.statsPerYear["N+2"] && customer.statsPerYear["N+2"].frequency ? customer.statsPerYear["N+2"].frequency : "",
              "Fréquence N+3": customer.statsPerYear["N+3"] && customer.statsPerYear["N+3"].frequency ? customer.statsPerYear["N+3"].frequency : "",
              "Coût compagnie N-1": customer.statsPerYear["N-1"] && customer.statsPerYear["N-1"].cost ? customer.statsPerYear["N-1"].cost + " €" : "",
              "Coût compagnie N": customer.statsPerYear["N0"] && customer.statsPerYear["N0"].cost ? customer.statsPerYear["N0"].cost + " €" : "",
              "Coût compagnie N+1": customer.statsPerYear["N+1"] && customer.statsPerYear["N+1"].cost ? customer.statsPerYear["N+1"].cost + " €" : "",
              "Coût compagnie N+2": customer.statsPerYear["N+2"] && customer.statsPerYear["N+2"].cost ? customer.statsPerYear["N+2"].cost + " €" : "",
              "Coût compagnie N+3": customer.statsPerYear["N+3"] && customer.statsPerYear["N+3"].cost ? customer.statsPerYear["N+3"].cost + " €" : ""
            };
            data.push(row);

            // rowsHeight.push({ hpt: 15 + 12 * (max - 1) })
          } catch (error) {
            console.log("customer simpleExport error", customer, error);
          }
        }

        /* generate a worksheet */
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      var ws = XLSX.utils.json_to_sheet(data);

      var columnsWidth = [];

      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = Object.keys(data[0])[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var key = _step2.value;

          columnsWidth.push({ wch: 35 });
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      ws["!cols"] = columnsWidth;
      ws["!rows"] = rowsHeight;
      ws["!freeze"] = { xSplit: "1", ySplit: "1", topLeftCell: "B2", activePane: "bottomRight", state: "frozen" };

      /* add to workbook */
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Clients");

      /* write workbook (use type 'binary') */
      var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      /* generate a download */
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) {
          view[i] = s.charCodeAt(i) & 0xff;
        }return buf;
      }

      saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), "export simplifié clients.xlsx");
    };
  }
})();